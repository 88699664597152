<template>
  <div>
    <el-row class="bgW">
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="hint(row,'add')">添加奖品</el-button>
      </el-col>
    </el-row>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="ID" prop="id" />
          <ElTableColumn label="奖品名称" prop="name" />
          <el-table-column
                 prop="url"
                 label="图标">
             <template slot-scope="scope">
                     <img :src="scope.row.url" min-width="70" height="70"/>
             </template>
          </el-table-column>

          <ElTableColumn label="中奖率" prop="probability" />
          <ElTableColumn label="奖品类型" prop="type" />
          <ElTableColumn label="奖品类型">
            <template slot-scope="{ row }">
              <div v-if="row.type == 1">产品</div>
              <div v-if="row.type == 2">优惠券</div>
              <div v-if="row.type == 3">积分</div>
            </template>
          </ElTableColumn>
          <ElTableColumn label="位置" prop="position" />
            <ElTableColumn label="状态">
              <template slot-scope="{ row }">
                <div v-if="row.status == 1">上架</div>
                <div v-if="row.status == 2">下架</div>
              </template>
            </ElTableColumn>
          <ElTableColumn label="操作" width="180" fixed="right">
            <template slot-scope="{ row }">
              <span class="theme" @click="hint(row,'update')">编辑</span>
            </template>
          </ElTableColumn>
        </el-table>
      </el-col>
      <el-dialog
        title="奖品"
        :visible.sync="dialogVisible"
        width="30%"
        >
        <el-form
          ref="detail"
          label-position="right"
          label-width="170px"
          :rules="rules"
          :model="detail"
          class="formBox"
        >

          <el-form-item label="奖品名称:" prop="name">
            <el-input
              v-model="detail.name"
              class="w300"
              maxlength="150"
              placeholder="请输入奖品名称"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="图片链接:" prop="url">
            <el-input
              v-model="detail.url"
              class="w300"
              maxlength="150"
              placeholder="请输入图片链接"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="中奖概率(百分制):" prop="probability">
            <el-input
              v-model="detail.probability"
              class="w300"
              maxlength="150"
              placeholder="请输入中奖概率"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="类型:">
            <el-radio-group v-model="detail.type" @change="getOptions">
              <el-radio :label="1">产品</el-radio>
              <el-radio :label="2">优惠券</el-radio>
              <el-radio :label="3">积分</el-radio>
            </el-radio-group>
          </el-form-item>
         <el-form-item label="产品选择:" prop="object" >
            <el-select
              v-model="detail.object"
              class="w300"
              clearable
              placeholder="请选择商品分类"
            >
              <el-option
                v-for="item in options.object"
                :key="item.id"
                :label="item.goods_name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
<!--          <el-form-item label="积分值:" prop="object" v-if="detail.type==3">
            <el-input
              v-model="detail.object"
              class="w300"
              maxlength="150"
              placeholder="请输入积分值"
              show-word-limit
            />
          </el-form-item> -->
          <el-form-item label="位置:" prop="position">
            <el-input
              v-model="detail.position"
              class="w300"
              maxlength="150"
              placeholder="请输入积分值"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="是否上架:">
            <el-radio-group v-model="detail.status">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </span>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { getMiniEventPrizeDetail,getObject,updatePrize  } from "@/api/sys";
class Search {
  event_id = ""; // 活动ID
}
class Detail {
  name="";
  url="";
  probability="";
  type=0;
  status=0;
  position="";
  object = "";
  id = "";
  event_id = "";

}
class Rules {
  name = [
    { required: true, message: '请填写奖品名称', trigger: 'blur', type:'string' }
  ];
  url = [
    { required: true, message: '请填写图片链接', trigger: 'blur', type:'url' }
  ];
  type = [
    { required: true, message: '请选择商品分类', trigger: 'change' }
  ];
  probability = [
    { required: true, message: '请填写中奖率(正整数)', trigger: 'change' }
  ];
  status = [
    { required: true, message: '请选择奖品状态', trigger: 'change' }
  ];
  position = [
    { required: true, message: '请填写奖品位置', trigger: 'blur' }
  ];
  object = [
    { required: true, message: '请选择奖品内容', trigger: 'change' }
  ];
}
class Options {
  object = [];
}
export default {
  name: "MiniEventList",
  data() {
    return {
      list: [], // 数据展示
      search: new Search(),
      detail: new Detail(),
      dialogVisible: false,
      rules: new Rules(),
      options:new Options(),
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.search.event_id = this.$route.query.id;
    this.getList();
    this.getOptions();
  },
  methods: {
    // 获取列表
    getList() {
      getMiniEventPrizeDetail({ ...this.search}).then(res => {
        this.list = res.data.list;
      });
    },
    hint(row,type){
      if(type == "update")
      {
        this.detail = row;
      }
      if(type == "add")
      {
        this.detail= new Detail();
        this.detail.event_id = this.$route.query.id;
      }
      this.getOptions();
      this.dialogVisible = true;
    },
    getOptions(){
      getObject({ type:this.detail.type}).then(res => {
        this.options.object = res.data.list;
      });
    },
    submit(){
      updatePrize({ ...this.detail}).then(res => {
        if(res.code == 200)
        {
          this.$message({
            message: res.msg,
            type: 'success'
          });
          this.dialogVisible = false;
          this.getList();
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
